import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const LandingPage = () => {
    const { lang } = useParams();
    const { t, i18n } = useTranslation();
    const [showLang, setShowLang] = useState(false);
    
    useEffect(() => {
        if (lang && (lang === 'en' || lang === 'es')) {
            i18n.changeLanguage(lang);
        }
    }, [lang, i18n]);

    const changeLanguage = (newLang) => {
        window.location.href = window.location.href.replace(`/${lang}`, `/${newLang}`);
        setShowLang(false);
    };

    return (
        <div>
            <header className="border-b border-gray-100 fixed-header">
                <div className="bg-first-brand relative green-bar">
                    <div className="absolute right-10 p-3 cursor-pointer top-[50%] translate-y-[-50%]">
                        <svg className="w-4 h-4 text-second-brand x-close-icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </div>
                </div>
                <div className="mx-auto px-3 min-[576px]:max-w-[540px] md:max-w-[720px] lg:max-w-[930px] xl:max-w-[1234px] hidden xl:block">
                    <div className="flex items-center justify-between relative gap-[48px]">
                        <div className="block max-w-[150px]">
                            <img src="/brand/goback-logo-black.png" alt="Logo" />
                        </div>
                        <nav className="inline-block text-left w-[63%]">
                            <ul className="flex items-center gap-10">
                                <li className="relative group transition-common after:bg-transparent after:cursor-pointer after:z-[560] after:absolute after:w-full after:h-10 after:left-0 after:bottom-0"><a className="py-9 text-gray-900 font-medium text-header-7 flex items-center gap-1" href="#"><span className="transition-common group-hover:text-success">Home</span><img src="assets/images/template/icons/angle-down.svg" alt="Arrow Down" /></a>
                                    <div className="hidden absolute rounded-lg left-0 bg-gray-0 border border-gray-80  shadow-drop-shadown-5 transition-common min-w-[220px] w-[400px] px-[30px] group-hover:block pt-[25px] top-[100%] z-[999] pb-[10px] border-b-[8px]">
                                        <div className="flex flex-col">
                                            <div className="flex items-center mb-4 gap-[9px]">
                                                <div className="flex items-center justify-center rounded-full bg-third-brand shrink-0 w-[30px] h-[30px]">
                                                    <svg className="w-4 h-4 text-first-brand" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                                                    </svg>
                                                </div><span className="font-bold text-first-brand text-header-9">Homepage</span>
                                            </div>
                                            <p className="text-mini-desc text-gray-300 font-medium mb-[26px]">Beautiful and Truly Unique Pre-Built Frontend Homepages Easily complete website for your business. New designs will be continuously added. Click to view the demo.</p>
                                            <ul className="grid grid-cols-2 gap-y-[11px] gap-x-[51px] mb-[30px]">
                                                <li><a className="flex items-center gap-1" href="index.html">
                                                    <svg className="text-gray-200 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                                                    </svg><span className="text-base text-first-brand font-medium transition-common hover:text-success">Home page 01</span></a></li>
                                                <li><a className="flex items-center gap-1" href="index-2.html">
                                                    <svg className="text-gray-200 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                                                    </svg><span className="text-base text-first-brand font-medium transition-common hover:text-success">Home page 02</span></a></li>
                                                <li><a className="flex items-center gap-1" href="index-3.html">
                                                    <svg className="text-gray-200 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                                                    </svg><span className="text-base text-first-brand font-medium transition-common hover:text-success">Home page 03</span></a></li>
                                                <li><a className="flex items-center gap-1" href="index-4.html">
                                                    <svg className="text-gray-200 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                                                    </svg><span className="text-base text-first-brand font-medium transition-common hover:text-success">Home page 04</span></a></li>
                                                <li><a className="flex items-center gap-1" href="index-5.html">
                                                    <svg className="text-gray-200 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                                                    </svg><span className="text-base text-first-brand font-medium transition-common hover:text-success">Home page 05</span></a></li>
                                                <li><a className="flex items-center gap-1" href="index-6.html">
                                                    <svg className="text-gray-200 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                                                    </svg><span className="text-base text-first-brand font-medium transition-common hover:text-success">Home page 06</span></a></li>
                                                <li><a className="flex items-center gap-1" href="index-7.html">
                                                    <svg className="text-gray-200 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                                                    </svg><span className="text-base text-first-brand font-medium transition-common hover:text-success">Home page 07</span></a></li>
                                                <li><a className="flex items-center gap-1" href="index-8.html">
                                                    <svg className="text-gray-200 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                                                    </svg><span className="text-base text-first-brand font-medium transition-common hover:text-success">Home page 08</span></a></li>
                                                <li><a className="flex items-center gap-1" href="index-9.html">
                                                    <svg className="text-gray-200 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                                                    </svg><span className="text-base text-first-brand font-medium transition-common hover:text-success">Home page 09</span></a></li>
                                                <li><a className="flex items-center gap-1" href="index-10.html">
                                                    <svg className="text-gray-200 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                                                    </svg><span className="text-base text-first-brand font-medium transition-common hover:text-success">Home page 10</span></a></li>
                                                <li><a className="flex items-center gap-1" href="index-11.html">
                                                    <svg className="text-gray-200 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                                                    </svg><span className="text-base text-first-brand font-medium transition-common hover:text-success">Home page 11</span></a></li>
                                                <li><a className="flex items-center gap-1" href="index-12.html">
                                                    <svg className="text-gray-200 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                                                    </svg><span className="text-base text-first-brand font-medium transition-common hover:text-success">Home page 12</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>


                                <li className="relative group after:bg-transparent after:cursor-pointer after:z-[560] after:absolute after:w-full after:h-10 after:left-0 after:bottom-0"><a className="py-9 text-gray-900 font-medium text-header-7 flex items-center gap-1" href="#"><span className="transition-common group-hover:text-success">Pages</span><img src="assets/images/template/icons/angle-down.svg" alt="Arrow Down" /></a>
                                    <ul className="hidden absolute left-0 py-2 bg-gray-0 border border-gray-80 rounded-lg shadow-drop-shadown-5 top-[100%] min-w-[220px] z-[999] group-hover:block">
                                        <li className="text-left pr-[15px]"><a className="text-gray-900 font-medium block transition-common text-[14px] leading-[23px] py-[6px] px-[22px] hover:text-success hover:pl-[25px]" href="register.html">Register</a></li>
                                        <li className="text-left pr-[15px]"><a className="text-gray-900 font-medium block transition-common text-[14px] leading-[23px] py-[6px] px-[22px] hover:text-success hover:pl-[25px]" href="login.html">Login</a></li>
                                        <li className="text-left pr-[15px]"><a className="text-gray-900 font-medium block transition-common text-[14px] leading-[23px] py-[6px] px-[22px] hover:text-success hover:pl-[25px]" href="coming-soon.html">Coming soon</a></li>
                                        <li className="text-left pr-[15px]"><a className="text-gray-900 font-medium block transition-common text-[14px] leading-[23px] py-[6px] px-[22px] hover:text-success hover:pl-[25px]" href="404.html">Error 404</a></li>
                                    </ul>
                                </li>
                                <li className="group"><a className="py-9 text-gray-900 font-medium text-header-7 flex items-center gap-1" href="contact.html"><span className="transition-common group-hover:text-success">Contact</span></a></li>
                            </ul>
                        </nav>
                        <div className="flex items-center gap-6">
                            <div className="relative">
                                <div className="dropdown-language-select inline-block cursor-pointer flex items-center gap-1" onClick={() => setShowLang(!showLang)} ><span className="inline-block text-gray-900 text-header-7 font-medium">{lang.toUpperCase()}</span><img src="assets/images/page/shop/arrow-down.svg" alt="arrow down icon" /></div>
                                {showLang && (
                                    <div className="absolute right-0 bg-gray-0 border border-gray-80 rounded-lg dropdown-language-list shadow-drop-shadown-5 top-[60px] min-w-[134px] z-[999]">
                                        <div className="dropdown-language-item p-4 cursor-pointer flex items-center gap-2 hover:bg-third-brand" onClick={() => changeLanguage('en')}><img src="assets/images/icons/icon-united-kingdom.svg" className="w-6" alt="Iori" /><span className="text-base text-first-brand font-medium">English</span></div>
                                        <div className="dropdown-language-item p-4 cursor-pointer flex items-center gap-2 hover:bg-third-brand" onClick={() => changeLanguage('es')}><img src="assets/images/icons/flag-spain.svg" alt="Iori" className="w-6" /><span className="text-base text-first-brand font-medium">Spanish</span></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-auto px-3 min-[576px]:max-w-[540px] md:max-w-[720px] lg:max-w-[930px] xl:max-w-[1434px] block xl:hidden">
                    <div className="flex items-center justify-between relative py-3 gap-[48px]">
                        <div className="block max-w-[150px]"><a href="index.html"><img src="/brand/goback-logo-black.png" alt="Logo" /></a></div>
                        <div className="flex items-center gap-6">
                            <div className="relative" onClick={() => setShowLang(!showLang)}>
                                <div className="dropdown-language-select inline-block cursor-pointer flex items-center gap-1"><span className="inline-block text-gray-900 text-header-7 font-medium">{lang.toUpperCase()}</span><img src="assets/images/page/shop/arrow-down.svg" alt="arrow down icon" /></div>
                                {showLang && (
                                    <div className="absolute right-0 bg-gray-0 border border-gray-80 rounded-lg dropdown-language-list shadow-drop-shadown-5 top-[49px] min-w-[134px] z-[999]">
                                        <div className="dropdown-language-item p-4 cursor-pointer flex items-center gap-2 hover:bg-third-brand" onClick={() => changeLanguage('en')}><img src="assets/images/icons/icon-united-kingdom.svg" alt="Iori" className="w-6" /><span className="text-base text-first-brand font-medium">English</span></div>
                                        <div className="dropdown-language-item p-4 cursor-pointer flex items-center gap-2 hover:bg-third-brand" onClick={() => changeLanguage('es')}><img src="assets/images/icons/flag-spain.svg" alt="Iori" className="w-6" /><span className="text-base text-first-brand font-medium">Spanush</span></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden fixed top-0 w-full bottom-0 right-0 bg-gray-0 h-screen shadow-drop-shadown-6 menu-mobile-iori max-w-[370px] z-[99999]">
                    <div className="h-full overflow-y-auto overflow-x-hidden">
                        <div className="relative py-4 border-b border-gray-80 mb-9 flex items-center justify-between px-[30px]"><a href="index.html"><img className="w-[60px]" src="assets/images/template/logo.svg" alt="Logo" /></a>
                            <svg className="text-gray-200 w-5 h-5 close-menu-icon cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </div>
                        <div className="block flex flex-wrap place-content-between px-[30px] h-[calc(100vh-55px)]">
                            <div className="block">
                                <ul className="tabHeader grid grid-cols-3">
                                    <li className="active"><a className="flex items-center gap-2 cursor-pointer border-b border-gray-80 pb-[13px]" href="#menuTab"><img className="shrink-0" src="assets/images/icons/icon-hamburger.svg" alt="Hamburger" /><span className="font-bold text-first-brand text-[14px] leading-[18px]">Menu</span></a></li>
                                    <li><a className="flex items-center gap-2 cursor-pointer border-b border-gray-80 pb-[13px]" href="#accountTab"><img className="shrink-0" src="assets/images/icons/icon-account-user.svg" alt="Account" /><span className="font-bold text-first-brand text-[14px] leading-[18px]">Account</span></a></li>
                                    <li><a className="flex items-center gap-2 cursor-pointer border-b border-gray-80 pb-[13px]" href="#notificationTab"><img className="shrink-0" src="assets/images/icons/icon-notification.svg" alt="Notification" /><span className="font-bold text-first-brand text-[14px] leading-[18px]">Notification</span></a></li>
                                </ul>
                                <div className="tabContents w-full clear-both mt-[30px]">
                                    <div className="tabBox" id="menuTab">
                                        <dl className="accordionHeaderWrap">
                                            <div className="block mb-[23px]">
                                                <dt className="accordion"><span className="text-base text-first-brand font-medium">Home</span></dt>
                                                <dd className="accordionBox mt-3">
                                                    <div className="flex flex-col"><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="index.html">Home page 01</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="index-2.html">Home page 02</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="index-3.html">Home page 03</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="index-4.html">Home page 04</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="index-5.html">Home page 05</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="index-6.html">Home page 06</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="index-7.html">Home page 07</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="index-8.html">Home page 08</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="index-9.html">Home page 09</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="index-10.html">Home page 10</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="index-11.html">Home page 11</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="index-12.html">Home page 12</a></div>
                                                </dd>
                                            </div>
                                            <div className="block mb-[23px]">
                                                <dt className="accordion"><span className="text-base text-first-brand font-medium">Company</span></dt>
                                                <dd className="accordionBox mt-3">
                                                    <div className="flex flex-col"><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="about.html">About Us</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="services.html">Our Services</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="pricing.html">Pricing Plan</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="team.html">Meet Our Team</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="help-center.html">Help Center</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="term-conditions.html">Term and Conditions</a></div>
                                                </dd>
                                            </div>
                                            <div className="block mb-[23px]">
                                                <dt className="accordion"><span className="text-base text-first-brand font-medium">Career</span></dt>
                                                <dd className="accordionBox mt-3">
                                                    <div className="flex flex-col"><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="career.html">Jobs Listing</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="job-details.html">Job Details</a></div>
                                                </dd>
                                            </div>
                                            <div className="block mb-[23px]">
                                                <dt className="accordion"><span className="text-base text-first-brand font-medium">Blog</span></dt>
                                                <dd className="accordionBox mt-3">
                                                    <div className="flex flex-col"><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="blog-listing01.html">Blog Listing 1</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="blog-listing02.html">Blog Listing 2</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="blog-details.html">Blog Details</a></div>
                                                </dd>
                                            </div>
                                            <div className="block mb-[23px]">
                                                <dt className="accordion"><span className="text-base text-first-brand font-medium">Shop</span></dt>
                                                <dd className="accordionBox mt-3">
                                                    <div className="flex flex-col"><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="shop-grid.html">Products Listing 1</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="shop-list.html">Products Listing 2</a><a className="text-gray-900 py-3 pl-3 font-medium text-[14px] leading-[24px]" href="product-details.html">Products Details</a></div>
                                                </dd>
                                            </div>

                                        </dl>
                                    </div>
                                    <div className="tabBox" id="accountTab">
                                        <ul className="flex flex-col gap-y-6">
                                            <li><a className="text-base font-medium text-first-brand" href="#">My Profile</a></li>
                                            <li><a className="text-base font-medium text-first-brand" href="#">Work Preferences</a></li>
                                            <li><a className="text-base font-medium text-first-brand" href="#">My Boosted Shots</a></li>
                                            <li><a className="text-base font-medium text-first-brand" href="#">My Collections</a></li>
                                            <li><a className="text-base font-medium text-first-brand" href="#">Account Settings</a></li>
                                            <li><a className="text-base font-medium text-first-brand" href="#">Go Pro</a></li>
                                            <li><a className="text-base font-medium text-first-brand" href="#">Sign Out</a></li>
                                        </ul>
                                    </div>
                                    <div className="tabBox" id="notificationTab">
                                        <div className="flex flex-col gap-y-[26px]">
                                            <div className="flex flex-col"><span className="font-bold text-first-brand text-header-9 mb-4">Today</span>
                                                <div className="flex flex-col gap-y-7">
                                                    <div className="flex justify-between"><img className="w-8 h-8 shrink-0" src="assets/images/template/user1.png" alt="User" />
                                                        <p className="text-mini-desc text-gray-500 font-medium mr-5 max-w-[190px]"><a className="font-bold text-first-brand" href="#">Steven Job</a>
                                                            like started a poll in your post "How to be a good trader in 2023"
                                                        </p><span className="text-mini-desc text-gray-300 font-medium">Just now</span>
                                                    </div>
                                                    <div className="flex justify-between"><img className="w-8 h-8 shrink-0" src="assets/images/template/user2.png" alt="User" />
                                                        <p className="text-mini-desc text-gray-500 font-medium mr-5 max-w-[190px]"><a className="font-bold text-first-brand" href="#">Steven Job</a>
                                                            like started a poll in your post "How to be a good trader in 2023"
                                                        </p><span className="text-mini-desc text-gray-300 font-medium">5 mins ago</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col"><span className="font-bold text-first-brand text-header-9 mb-4">Yesterday</span>
                                                <div className="flex flex-col gap-y-7">
                                                    <div className="flex justify-between"><img className="w-8 h-8 shrink-0" src="assets/images/template/user3.png" alt="User" />
                                                        <p className="text-mini-desc text-gray-500 font-medium mr-5 max-w-[190px]"><a className="font-bold text-first-brand" href="#">Steven Job</a>
                                                            like started a poll in your post "How to be a good trader in 2023"
                                                        </p><span className="text-mini-desc text-gray-300 font-medium">5:32 PM</span>
                                                    </div>
                                                    <div className="flex justify-between"><img className="w-8 h-8 shrink-0" src="assets/images/template/user4.png" alt="User" />
                                                        <p className="text-mini-desc text-gray-500 font-medium mr-5 max-w-[190px]"><a className="font-bold text-first-brand" href="#">Steven Job</a>
                                                            like started a poll in your post "How to be a good trader in 2023"
                                                        </p><span className="text-mini-desc text-gray-300 font-medium">3:25 PM</span>
                                                    </div>
                                                    <div className="flex justify-between"><img className="w-8 h-8 shrink-0" src="assets/images/template/user5.png" alt="User" />
                                                        <p className="text-mini-desc text-gray-500 font-medium mr-5 max-w-[190px]"><a className="font-bold text-first-brand" href="#">Steven Job</a>
                                                            like started a poll in your post "How to be a good trader in 2023"
                                                        </p><span className="text-mini-desc text-gray-300 font-medium">1:23 AM</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="block pb-8 mt-6">
                                <div className="pt-9 border-t border-gray-80 border-b pb-[54px]">
                                    <div className="flex flex-col">
                                        <p className="text-mini-desc font-medium text-gray-500 mb-6">Download our Apps and get <br /> extra 15% Discount on your first Order…!</p>
                                        <div className="flex items-center gap-x-4 mb-9"><img src="assets/images/AppStore.svg" alt="AppStore" /><img src="assets/images/AppGooglePlay.svg" alt="AppGooglePlay" />
                                        </div>
                                        <p className="text-mini-desc font-medium text-gray-400 mb-2.5">Secured Payment Gateways</p><img src="assets/images/template/payment-method.png" alt="Payment" />
                                    </div>
                                </div>
                                <p className="text-mini-desc font-medium text-gray-500 mt-[35px]">Copyright 2023 © iori - Creative Agency Template.</p>
                                <span className="text-mini-desc font-medium text-gray-500">Designed by<a className="text-first-brand underline" href="#">AliThemes</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="overflow-hidden bg-gray-60 inline-block w-full">
                <div className="mx-auto px-3 min-[576px]:max-w-[540px] md:max-w-[720px] lg:max-w-[930px] xl:max-w-[1234px]">
                    <div className="grid grid-cols-1 items-end xl:grid-cols-2">
                        <div className="hidden xl:block">
                            <div className="relative w-full text-left before:w-[620px] before:h-[620px] before:absolute before:rounded-[50%] before:bottom-[-140px] before:left-[-15px] before:z-[1] before:bg-second-brand">
                                <div className="p-4 bg-gray-0 border border-third-brand rounded-lg shadow-drop-shadown-1 absolute hero-thumb-animation z-[2] bottom-[210px] left-[-120px]">
                                    <div className="flex items-center gap-x-3">
                                        <div className="w-14 h-14 overflow-hidden rounded-full"><img className="w-full h-full object-cover" src="assets/images/homepage-11-avatar-1.png" alt="Avatar" />
                                        </div>
                                        <div className="flex flex-col">
                                            <div className="flex items-center gap-x-5"><span className="text-header-8 font-bold text-first-brand">Bessie Cooper</span>
                                                <div className="flex items-center relative z-10 overflow-hidden gap-[3px] max-w-[72px]"><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" />
                                                    <div className="flex items-center absolute overflow-hidden gap-[3px] w-[100%]"><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" />
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="text-mini-desc text-gray-500 font-medium max-w-[216px]">Duis justo nulla, pulvinar at dolor dapibus, finibus cursus massa</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-4 bg-gray-0 border border-third-brand rounded-lg shadow-drop-shadown-1 absolute right-7 hero-thumb-sm-animation bottom-5 z-[4]">
                                    <div className="flex items-center gap-x-3">
                                        <div className="w-14 h-14 overflow-hidden rounded-full"><img className="w-full h-full object-cover" src="assets/images/homepage-11-avatar-1.png" alt="Avatar" />
                                        </div>
                                        <div className="flex flex-col">
                                            <div className="flex items-center gap-x-5"><span className="text-header-8 font-bold text-first-brand">Bessie Cooper</span>
                                                <div className="flex items-center relative z-10 overflow-hidden gap-[3px] max-w-[72px]"><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" />
                                                    <div className="flex items-center absolute overflow-hidden gap-[3px] w-[100%]"><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" />
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="text-mini-desc text-gray-500 font-medium max-w-[216px]">Duis justo nulla, pulvinar at dolor dapibus, finibus cursus massa</p>
                                        </div>
                                    </div>
                                </div><img className="relative block z-[3] max-w-[90%]" src="assets/images/page/homepage6/banner.png" alt="Hero" />
                            </div>
                        </div>
                        <div className="flex flex-col py-16">
                            <div className="flex items-center gap-x-3 mb-4"><span className="w-12 bg-first-brand h-[1px]"></span><span className="font-medium text-gray-400 text-[18px] leading-[18px]">{t('landing.subtitle')}</span>
                            </div>
                            <p className="text-first-brand font-extrabold text-[34px] leading-[40px] sm:text-[50px] sm:leading-[60px] lg:text-[64px] lg:leading-[72px] mb-6">{t('landing.title')}</p>
                            <p className="text-base font-medium text-gray-500 mb-7 xl:max-w-[601px]">{t('landing.description')}</p>
                            <div className="flex items-start gap-x-9 flex-col gap-y-4 sm:flex-row sm:items-center">
                                <button className="py-4 px-6 bg-first-brand rounded transition-common hover:bg-first-brand-hover">
                                    <span className="font-bold text-header-9 text-second-brand">Contact Us</span>
                                </button>
                                <a className="flex items-center gap-x-3 group" href="#">
                                    <span className="font-bold text-header-9 text-gray-900 transition-common group-hover:text-success">{t('landing.learnMore')}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-gray-80 py-[30px]">
                <div className="mx-auto px-3 min-[576px]:max-w-[540px] md:max-w-[720px] lg:max-w-[930px] xl:max-w-[1234px]">
                    <ul className="text-center">
                        <li className="inline-block px-5 min-w-[100px] py-2.5"><img className="w-full h-full object-cover" src="assets/images/icons/icon-placed-1.svg" alt="Iori" />
                        </li>
                        <li className="inline-block px-5 min-w-[100px] py-2.5"><img className="w-full h-full object-cover" src="assets/images/icons/icon-cuebiq-1.svg" alt="Iori" />
                        </li>
                        <li className="inline-block px-5 min-w-[100px] py-2.5"><img className="w-full h-full object-cover" src="assets/images/icons/icon-factual-1.svg" alt="Iori" />
                        </li>
                        <li className="inline-block px-5 min-w-[100px] py-2.5"><img className="w-full h-full object-cover" src="assets/images/icons/icon-place-iq-1.svg" alt="Iori" />
                        </li>
                        <li className="inline-block px-5 min-w-[100px] py-2.5"><img className="w-full h-full object-cover" src="assets/images/icons/icon-klippa-1.svg" alt="Iori" />
                        </li>
                    </ul>
                </div>
            </div>

            {/* product */}
            <div className="mx-auto px-3 min-[576px]:max-w-[540px] md:max-w-[720px] lg:max-w-[930px] xl:max-w-[1234px]">
                <p className="text-first-brand font-extrabold text-[25px] leading-[35px] sm:text-[30px] sm:leading-[40px] md:text-[36px] md:leading-[46px] lg:text-header-2 mx-auto text-center max-w-[1009px] mb-[50px] lg:mb-[100px] mt-[64px] lg:mt-[128px]">{t("landing.product.title")}</p>
                <div className="grid grid-cols-12 items-center mb-28 xl:gap-[70px]">
                    <div className="col-span-12 mb-16 xl:col-span-7 xl:mb-0">
                        <div className="relative before:absolute before:w-full before:h-full before:rounded-[900px] before:bg-third-brand before:bottom-[-9px] before:right-[-7px] before:z-[1]">
                            <div className="absolute top-0 hero-thumb-sm-2-animation right-4 z-[3] w-[75px] h-[75px] md:w-fit md:h-fit">
                                <img className="w-32 h-full object-cover hidden md:block" src="assets/images/goback-round.png" alt="Circle" />
                            </div>
                            <div className="absolute bottom-0 hero-thumb-animation left-0 z-[3] w-[100px] h-[100px] md:w-fit md:h-fit">
                                <img className="max-w-full" srcset="assets/images/homepage-1-circle-1.png 2x" alt="Circle" />
                            </div>
                            <img className="max-w-full relative w-full z-[2] rounded-[900px]" srcset="assets/images/homepage-1-project-1.png 2x" alt="Project" />
                        </div>
                    </div>
                    <div className="col-span-12 xl:col-span-5">
                        <div className="flex flex-col">
                            <button className="bg-third-brand rounded text-first-brand font-bold w-fit pb-2 text-[12px] leading-[12px] pl-[11px] pr-[10px] pt-[5px] mb-2">{t("landing.product.realTimeBadge")}</button>
                            <p className="text-first-brand font-bold text-[22px] leading-[28px] lg:text-header-3 mb-3">{t("landing.product.realtimeTitle")}</p>
                            <p className="text-base text-gray-400 font-medium mb-5">{t("landing.product.realtimeDescription")}</p>
                            <ul className="grid grid-cols-1 gap-y-[18px] mb-[68px] sm:grid-cols-2">
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.product.realtimeFeatures.0")}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.product.realtimeFeatures.1")}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.product.realtimeFeatures.2")}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.product.realtimeFeatures.3")}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.product.realtimeFeatures.4")}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.product.realtimeFeatures.5")}</span>
                                    </div>
                                </li>
                            </ul>
                            <div className="flex items-center gap-6">
                                <button className="bg-first-brand rounded py-4 px-6 font-bold text-second-brand text-header-9 hover-up-all hover:bg-first-brand-hover">{t("landing.product.realtimeAction1")}</button>
                                <a className="flex items-center gap-x-3 group" href="#"><span className="font-bold text-header-9 text-gray-900 transition-common group-hover:text-success">{t("landing.product.realtimeAction2")}</span>
                                    <svg className="w-5 h-5 text-gray-900 transition-common" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 items-center mb-24 xl:gap-[70px]">
                    <div className="col-span-12 mb-16 xl:col-span-5 xl:mb-0">
                        <div className="flex flex-col">
                            <button className="bg-third-brand rounded text-first-brand font-bold w-fit pb-2 text-[12px] leading-[12px] pl-[11px] pr-[10px] pt-[5px] mb-2">{t("landing.product.auditBadge")}</button>
                            <p className="text-first-brand font-bold text-[22px] leading-[28px] lg:text-header-3 mb-3">{t("landing.product.auditTitle")}</p>
                            <p className="text-base text-gray-400 font-medium mb-5">{t("landing.product.auditDescription")}</p>
                            <ul className="grid grid-cols-1 gap-y-[18px] sm:grid-cols-2 mb-[68px]">
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.product.auditFeatures.0")}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.product.auditFeatures.1")}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.product.auditFeatures.2")}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.product.auditFeatures.3")}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.product.auditFeatures.4")}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.product.auditFeatures.5")}</span>
                                    </div>
                                </li>
                            </ul>
                            <div className="flex items-center gap-6">
                                <button className="bg-first-brand rounded py-4 px-6 font-bold text-second-brand text-header-9 hover-up-all hover:bg-first-brand-hover">{t("landing.product.auditAction1")}</button>
                                <a className="flex items-center gap-x-3 group" href="#"><span className="font-bold text-header-9 text-gray-900 transition-common group-hover:text-success">{t("landing.product.auditAction2")}</span>
                                    <svg className="w-5 h-5 text-gray-900 transition-common" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 xl:col-span-7">
                        <div className="relative before:absolute before:w-full before:h-full before:rounded-[900px] before:bg-third-brand before:bottom-[-9px] before:right-[-7px] before:z-[1]">
                            <div className="absolute top-0 hero-thumb-sm-2-animation right-4 z-[3]">
                                <img className="max-w-full" srcset="assets/images/homepage-1-union.png 2x" alt="Union" />
                            </div>
                            <div className="absolute bottom-0 hero-thumb-animation left-0 z-[3]">
                                <img className="w-32 h-full object-cover hidden md:block" src="assets/images/goback-round.png" alt="Circle" />
                            </div>
                            <img className="max-w-full relative w-full z-[2] rounded-[900px]" srcset="assets/images/homepage-1-project-2.png 2x" alt="Project" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Blog */}
            <div className="w-full bg-gray-60 relative h-max mb-[81px]">
                <div className="w-full xl:absolute xl:top-1/2 xl:-translate-y-1/2">
                    <div className="mx-auto px-3 min-[576px]:max-w-[540px] md:max-w-[720px] lg:max-w-[930px] xl:max-w-[1434px]">
                        <div className="grid grid-cols-12 items-center">
                            <div className="col-span-12 ml-0 xl:col-span-7 2xl:col-span-6 m-[30px]">
                                <h1 className="text-first-brand font-extrabold text-3xl leading-9 mr xl:text-[48px] xl:leading-[56px] mb-[20px]">{t("landing.blog.title")}</h1>
                                <div className="grid grid-cols-12">
                                    <div className="col-span-12 xl:col-span-9 xl:pr-10">
                                        <p className="text-header-7 text-gray-500 font-medium">{t("landing.blog.description")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 item-center">
                    <div className="col-span-6"></div>
                    <div className="col-span-6 pl-[70px]">
                        <img className="w-full hidden xl:block" src="assets/images/page/blog/banner.png" alt="Banner" />
                    </div>
                </div>
            </div>

            {/* Core Features */}
            <div className="mx-auto px-3 min-[576px]:max-w-[540px] md:max-w-[720px] lg:max-w-[930px] xl:max-w-[1234px]">
                <div className="grid grid-cols-12 items-center">
                    <div className="col-span-12 mb-7 xl:col-span-7 xl:mb-0">
                        <div className="relative max-w-[570px]"><img className="block rounded-t-[500px]" srcset="assets/images/homepage-4-follower.png 2x" alt="Follower" />
                            <div className="absolute bottom-10 hero-thumb-animation max-w-40 z-[3] sm:w-fit sm:h-fit right-[-10px] sm:right-[-80px]"><img src="assets/images/goback-round.png" alt="Certify" className="hidden md:block" /></div>
                        </div>
                    </div>
                    <div className="col-span-12 xl:col-span-5">
                        <div className="flex flex-col xl:max-w-[570px]">
                            <button className="bg-third-brand rounded text-first-brand font-bold w-fit pb-2 text-[12px] leading-[12px] pl-[11px] pr-[10px] pt-[5px] mb-2">{t("landing.coreFeatures.badge")}</button>
                            <p className="text-first-brand font-bold text-[22px] leading-[28px] lg:text-header-3 mb-3">{t("landing.coreFeatures.title")}</p>
                            <p className="text-base text-gray-400 font-medium mb-5">{t("landing.coreFeatures.description")}</p>
                            <ul className="grid grid-cols-1 gap-y-[18px] sm:grid-cols-2 mb-[68px]">
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.coreFeatures.features.0")}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.coreFeatures.features.1")}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.coreFeatures.features.2")}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.coreFeatures.features.3")}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.coreFeatures.features.4")}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center gap-x-3">
                                        <svg className="w-5 h-5 text-[#06D6A0]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                        </svg><span className="text-header-9 text-gray-500 font-medium">{t("landing.coreFeatures.features.5")}</span>
                                    </div>
                                </li>
                            </ul>
                            <div className="flex items-center gap-6">
                                <button className="bg-first-brand rounded py-4 px-6 font-bold text-second-brand text-header-9 hover-up-all hover:bg-first-brand-hover">{t("landing.coreFeatures.action1")} </button><a className="flex items-center gap-x-3 group" href="#"><span className="font-bold text-header-9 text-gray-900 transition-common group-hover:text-success">{t("landing.coreFeatures.action2")}</span>
                                    <svg className="w-5 h-5 text-gray-900 transition-common group-hover:rotate-[-45deg]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                    </svg></a>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Core values */}
                <div className="mx-auto px-3 min-[576px]:max-w-[540px] md:max-w-[720px] lg:max-w-[930px] xl:max-w-[1434px]">
                    <div className="grid grid-cols-1 gap-8 pt-14 pb-9 lg:pb-[71px] lg:pt-28 lg:grid-cols-3">
                        <div className="flex flex-col gap-y-8">
                            <p className="text-first-brand font-extrabold text-[34px] leading-[40px] sm:text-[50px] sm:leading-[60px] lg:text-[64px] lg:leading-[72px]">{t("landing.coreFeatures.core")}</p>
                            <p className="text-base font-medium text-gray-400 mb-3 lg:max-w-[333px]">{t("landing.coreFeatures.coreDescription")}</p>
                        </div>
                        <div className="flex flex-col gap-y-6">
                            <div className="flex items-start gap-x-4"><img className="max-w-full check-icon" src="assets/images/about-1/check.svg" alt="" />
                                <div className="flex flex-col pb-6 border-dashed border-gray-300 gap-y-[5px] border-b">
                                    <p className="font-bold text-first-brand text-[18px] leading-[24px] sm:text-xl">{t("landing.coreFeatures.coreFeatures.0.title")}</p>
                                    <p className="text-base font-medium text-gray-500">{t("landing.coreFeatures.coreFeatures.0.description")}</p>
                                </div>
                            </div>
                            <div className="flex items-start gap-x-4"><img className="max-w-full check-icon" src="assets/images/about-1/check.svg" alt="" />
                                <div className="flex flex-col pb-6 border-dashed border-gray-300 gap-y-[5px] border-b">
                                    <p className="font-bold text-first-brand text-[18px] leading-[24px] sm:text-xl">{t("landing.coreFeatures.coreFeatures.1.title")}</p>
                                    <p className="text-base font-medium text-gray-500">{t("landing.coreFeatures.coreFeatures.1.description")}</p>
                                </div>
                            </div>
                            <div className="flex items-start gap-x-4"><img className="max-w-full check-icon" src="assets/images/about-1/check.svg" alt="" />
                                <div className="flex flex-col pb-6 border-dashed border-gray-300 gap-y-[5px] border-b-0">
                                    <p className="font-bold text-first-brand text-[18px] leading-[24px] sm:text-xl">{t("landing.coreFeatures.coreFeatures.2.title")}</p>
                                    <p className="text-base font-medium text-gray-500">{t("landing.coreFeatures.coreFeatures.2.description")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-6">
                            <div className="flex items-start gap-x-4">
                                <img className="max-w-full check-icon" src="assets/images/about-1/check.svg" alt="" />
                                <div className="flex flex-col pb-6 border-dashed border-gray-300 gap-y-[5px] border-b">
                                    <p className="font-bold text-first-brand text-[18px] leading-[24px] sm:text-xl">{t("landing.coreFeatures.coreFeatures.3.title")}</p>
                                    <p className="text-base font-medium text-gray-500">{t("landing.coreFeatures.coreFeatures.3.description")}</p>
                                </div>
                            </div>
                            <div className="flex items-start gap-x-4">
                                <img className="max-w-full check-icon" src="assets/images/about-1/check.svg" alt="" />
                                <div className="flex flex-col pb-6 border-dashed border-gray-300 gap-y-[5px] border-b">
                                    <p className="font-bold text-first-brand text-[18px] leading-[24px] sm:text-xl">{t("landing.coreFeatures.coreFeatures.4.title")}</p>
                                    <p className="text-base font-medium text-gray-500">{t("landing.coreFeatures.coreFeatures.4.description")}</p>
                                </div>
                            </div>
                            <div className="flex items-start gap-x-4">
                                <img className="max-w-full check-icon" src="assets/images/about-1/check.svg" alt="" />
                                <div className="flex flex-col pb-6 border-dashed border-gray-300 gap-y-[5px] border-b-0">
                                    <p className="font-bold text-first-brand text-[18px] leading-[24px] sm:text-xl">{t("landing.coreFeatures.coreFeatures.5.title")}</p>
                                    <p className="text-base font-medium text-gray-500">{t("landing.coreFeatures.coreFeatures.5.description")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Trusted */}
            <div className="bg-first-brand mb-[65px] lg:mb-[131px]">
                <div className="mx-auto px-3 min-[576px]:max-w-[540px] md:max-w-[720px] lg:max-w-[930px] xl:max-w-[1434px]">
                    <div className="grid grid-cols-1 items-center py-[90px] xl:grid-cols-2">
                        <div className="flex flex-col gap-2 mb-3 xl:mb-0">
                            <div className="flex items-center relative z-10 overflow-hidden gap-[3px] max-w-[72px]"><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" />
                                <div className="flex items-center absolute overflow-hidden gap-[3px] w-[100%]"><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" />
                                </div>
                            </div>
                            <p className="text-gray-0 font-extrabold xl:max-w-[347px] text-[25px] leading-[35px] sm:text-[30px] sm:leading-[40px] md:text-[36px] md:leading-[46px] lg:text-header-2">{t("landing.trusted.title")}</p>
                        </div>
                        <div className="flex flex-col gap-5">
                            <p className="text-header-9 text-gray-0 font-medium">{t("landing.trusted.description")}</p>
                            <div className="flex items-center justify-between flex-wrap gap-y-4">
                                <div className="flex flex-col gap-y-1 text-gray-0"><span className="text-header-2 font-extrabold">100m</span><span className="font-medium text-header-7">{t("landing.trusted.sections.0")}</span>
                                </div>
                                <div className="flex flex-col gap-y-1 text-gray-0"><span className="text-header-2 font-extrabold">10+</span><span className="font-medium text-header-7">{t("landing.trusted.sections.1")}</span>
                                </div>
                                <div className="flex flex-col gap-y-1 text-gray-0"><span className="text-header-2 font-extrabold">100+</span><span className="font-medium text-header-7">{t("landing.trusted.sections.2")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Recomendaciones */}
            <div className="mx-auto px-3 min-[576px]:max-w-[540px] md:max-w-[720px] lg:max-w-[930px] xl:max-w-[1234px]">
                <div className="grid grid-cols-1 gap-[30px] lg:grid-cols-3 mb-[70px] lg:mb-[140px]">
                    <div className="flex flex-col mt-10 lg:mt-[77px]">
                        <div className="overflow-hidden w-[75%]">
                            <img className="max-w-full w-full h-full object-cover" srcset="assets/images/homepage-10-person-3.png 2x" alt="Custommer" />
                        </div>
                        <p className="text-first-brand font-extrabold text-[25px] leading-[35px] sm:text-[30px] sm:leading-[40px] md:text-[36px] md:leading-[46px] lg:text-header-2 mt-8 mb-5 lg:max-w-[369px]">What our custommers are saying</p>
                        <p className="text-header-7 text-gray-500 font-medium mb-7">Hear from our users who have saved thousands on their Startup and SaaS solution spend.</p><a className="flex items-center gap-x-3 group" href="#"><span className="font-bold text-header-9 text-gray-900 transition-common group-hover:text-success">View All</span>
                            <svg className="w-5 h-5 text-gray-900 transition-common group-hover:rotate-[-45deg]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                            </svg></a>
                    </div>
                    <div className="flex flex-col gap-y-9 mt-10 lg:mt-[77px]">
                        <div className="pt-8 px-8 pb-6 bg-gray-0 border rounded-lg border-gray-80 transition-common hover:shadow-drop-shadown-1 hover:bg-second-brand">
                            <div className="flex flex-col gap-y-4 relative">
                                <div className="flex items-center gap-x-3">
                                    <div className="w-14 h-14 overflow-hidden rounded-full"><img className="w-full h-full object-cover" src="assets/images/homepage-11-avatar-1.png" alt="Avatar" />
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-header-8 font-bold text-first-brand">Guy Hawkins</p>
                                        <p className="text-mini-desc font-medium text-gray-500">Bank of America</p>
                                    </div>
                                </div>
                                <p className="text-base font-medium text-gray-500 pb-5 border-b border-gray-50">Access the same project through five different dynamic views: a kanban board, Gantt chart, spreadsheet, calendar or simple task list. When team members can choose the work style that suits them best, productivity and engagement skyrocket. Maecenas lobortis risus.</p>
                                <div className="flex items-center justify-between"><span className="text-mini-desc text-gray-500 font-medium">29 November 2022</span>
                                    <div className="flex items-center relative z-10 overflow-hidden gap-[3px] max-w-[72px]"><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" />
                                        <div className="flex items-center absolute overflow-hidden gap-[3px] w-[100%]"><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute top-0 right-0 overflow-hidden rotateQuotesIcon w-7 xl:w-10"><img className="w-full h-full object-cover" src="assets/images/icons/icon-quotes.svg" alt="Quotes Icon" />
                                </div>
                            </div>
                        </div>
                        <div className="pt-8 px-8 pb-6 bg-gray-0 border rounded-lg border-gray-80 transition-common hover:shadow-drop-shadown-1 hover:bg-second-brand">
                            <div className="flex flex-col gap-y-4 relative">
                                <div className="flex items-center gap-x-3">
                                    <div className="w-14 h-14 overflow-hidden rounded-full"><img className="w-full h-full object-cover" src="assets/images/homepage-11-avatar-2.png" alt="Avatar" />
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-header-8 font-bold text-first-brand">Esther Howard</p>
                                        <p className="text-mini-desc font-medium text-gray-500">Bank of America</p>
                                    </div>
                                </div>
                                <p className="text-base font-medium text-gray-500 pb-5 border-b border-gray-50">Vivamus venenatis turpis at elit aliquam, in mattis felis ullamcorper. Donec vel elit at diam suscipit volutpat. Donec rhoncus, magna vitae gravida condimentum, massa mauris fermentum est, vitae euismod leo magna vestibulum nunc</p>
                                <div className="flex items-center justify-between"><span className="text-mini-desc text-gray-500 font-medium">29 November 2022</span>
                                    <div className="flex items-center relative z-10 overflow-hidden gap-[3px] max-w-[72px]"><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" />
                                        <div className="flex items-center absolute overflow-hidden gap-[3px] w-[100%]"><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute top-0 right-0 overflow-hidden rotateQuotesIcon w-7 xl:w-10"><img className="w-full h-full object-cover" src="assets/images/icons/icon-quotes.svg" alt="Quotes Icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-9">
                        <div className="pt-8 px-8 pb-6 bg-gray-0 border rounded-lg border-gray-80 transition-common hover:shadow-drop-shadown-1 hover:bg-second-brand">
                            <div className="flex flex-col gap-y-4 relative">
                                <div className="flex items-center gap-x-3">
                                    <div className="w-14 h-14 overflow-hidden rounded-full"><img className="w-full h-full object-cover" src="assets/images/homepage-11-avatar-1.png" alt="Avatar" />
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-header-8 font-bold text-first-brand">Bessie Cooper</p>
                                        <p className="text-mini-desc font-medium text-gray-500">Bank of America</p>
                                    </div>
                                </div>
                                <p className="text-base font-medium text-gray-500 pb-5 border-b border-gray-50">Duis justo nulla, pulvinar at dolor dapibus, finibus cursus massa. Praesent quam diam, faucibus tristique enim in, rhoncus aliquam lorem. Vestibulum vestibulum pellentesque nunc sit amet ullamcorper. Praesent ligula felis</p>
                                <div className="flex items-center justify-between"><span className="text-mini-desc text-gray-500 font-medium">29 November 2022</span>
                                    <div className="flex items-center relative z-10 overflow-hidden gap-[3px] max-w-[72px]"><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" />
                                        <div className="flex items-center absolute overflow-hidden gap-[3px] w-[100%]"><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute top-0 right-0 overflow-hidden rotateQuotesIcon w-7 xl:w-10"><img className="w-full h-full object-cover" src="assets/images/icons/icon-quotes.svg" alt="Quotes Icon" />
                                </div>
                            </div>
                        </div>
                        <div className="pt-8 px-8 pb-6 bg-gray-0 border rounded-lg border-gray-80 transition-common hover:shadow-drop-shadown-1 hover:bg-second-brand">
                            <div className="flex flex-col gap-y-4 relative">
                                <div className="flex items-center gap-x-3">
                                    <div className="w-14 h-14 overflow-hidden rounded-full"><img className="w-full h-full object-cover" src="assets/images/homepage-11-avatar-2.png" alt="Avatar" />
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-header-8 font-bold text-first-brand">Albert Flores</p>
                                        <p className="text-mini-desc font-medium text-gray-500">Bank of America</p>
                                    </div>
                                </div>
                                <p className="text-base font-medium text-gray-500 pb-5 border-b border-gray-50">Vivamus venenatis turpis at elit aliquam, in mattis felis ullamcorper. Donec vel elit at diam suscipit volutpat. Donec rhoncus, magna vitae gravida condimentum, massa mauris fermentum est, vitae euismod leo magna vestibulum nunc</p>
                                <div className="flex items-center justify-between"><span className="text-mini-desc text-gray-500 font-medium">29 November 2022</span>
                                    <div className="flex items-center relative z-10 overflow-hidden gap-[3px] max-w-[72px]"><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" /><img src="assets/images/icons/icon-star.svg" alt="star icon" />
                                        <div className="flex items-center absolute overflow-hidden gap-[3px] w-[100%]"><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" /><img src="assets/images/icons/icon-star-yellow.svg" alt="star icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute top-0 right-0 overflow-hidden rotateQuotesIcon w-7 xl:w-10"><img className="w-full h-full object-cover" src="assets/images/icons/icon-quotes.svg" alt="Quotes Icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="block global-footer bg-gray-60">
                <div className="pt-[98px] pb-[64px] bg-gray-60">
                    <div className="mx-auto px-3 min-[576px]:max-w-[540px] md:max-w-[720px] lg:max-w-[930px] xl:max-w-[1234px]">
                        <div className="grid grid-cols-1 gap-y-7 gap-x-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5">
                            <div className="flex flex-col">
                                <div className="mb-4 w-40 md:w-full"><img src="/brand/goback-logo-black.png" alt="Logo" /></div>
                                <p className="text-base font-medium text-gray-400 mb-5">Your trusted audit and <br /> anti fraud provider</p>
                                <p className="font-bold text-first-brand text-header-6 mb-3">Follow Us</p>
                                <div className="flex items-center gap-2">
                                    <img className="shrink-0" src="assets/images/template/icons/fb.svg" alt="Iori" />
                                    <img className="shrink-0" src="assets/images/template/icons/inst.svg" alt="Iori" />
                                    <img className="shrink-0" src="assets/images/template/icons/tw.svg" alt="Iori" />
                                    <img className="shrink-0" src="assets/images/template/icons/in.svg" alt="Iori" />
                                    <img className="shrink-0" src="assets/images/template/icons/youtube.svg" alt="Iori" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-auto px-3 min-[576px]:max-w-[540px] md:max-w-[720px] lg:max-w-[930px] xl:max-w-[1234px]">
                    <div className="block border-t border-gray-80 py-[30px]">
                        <div className="flex items-center justify-between text-gray-300 font-medium text-xs flex-wrap gap-y-1 md:text-base">
                            <div className="flex items-center gap-4 md:gap-[50px]" style={{ display: "none" }}><a href="term-conditions.html">Privacy policy</a><a href="term-conditions.html">Cookies</a><a href="term-conditions.html">Terms of service</a>
                            </div>
                            <p>©GoBack 2024. All right reversed.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
};

export default LandingPage;
