import './App.css';
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route, Navigate } from "react-router-dom";
import LandingPage from './pages/en/landing/LandingPage';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {/* Redirect from root to default language */}
      <Route path="/" element={<Navigate to="/en" replace />} />
      
      {/* Language-specific routes */}
      <Route path=":lang">
        <Route index element={<LandingPage />} />
        {/* Add other routes here that should be language-prefixed */}
      </Route>
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
